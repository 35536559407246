.btn {
  padding: 0.5rem 1rem;
  border: 0;
  border-radius: 0.5px;
  background-color: darkred;
  color: white;
  font-weight: bold;
}

.btn--red {
  background-color: darkred;
}

.btn--green {
  background-color: darkgreen;
}
