.content {
  height: 100%;
}

.container {
  width: 100%;
  max-width: 1440px;
  padding-left: $gutter-desktop;
  padding-right: $gutter-desktop;
  margin: 0 auto;
}

.perfect-center {
  display: flex;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}
